body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

	/* background-image: url('../bg6.png'); */
	/* background-size: cover; */
	/* background-attachment: fixed; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
	width: 80%;
}

.no-shadow {
	box-shadow: None;
}

.nav-wrapper {
	margin-top: 15px;
	margin-bottom: 15px;
}

.nav-item {
	padding-right: 20px;
	padding-left: 20px;
	font-size: 1.07rem;
	color: #424242;
}

.nav-item:hover, .nav-item:active {
	background-color: #fff;
	color: #00aeff;
}

.logo-img {
	width: 60px;
}

.active {
	color: #00aeff;
}

.wallpaper {
	width: 50%;
}

.inline {
	display: inline;
	margin-right: 10px;
}

.valign {
	vertical-align: middle;
}

.bold {
	font-weight: 900;
}

.my-wrapper {
	height: 100%;
}

.margin-top-text {
	display: block;
	padding-top: 30px;
	padding-bottom: 30px;
}

.appear-on-hover {
	display: none;
	background-color: #ff8f00;
	color: #fff;
	padding: 15px;
}

.card-hover-controller:hover .appear-on-hover {
	display: block;
	box-shadow: 0px 0px 3px #000;
}

/* Reveal effect */
.reveal {
	position: relative;
	transform: translateY(100px);
	opacity: 0;
	transition: all 1.5s ease;
}

.reveal.reveal-active {
	transform: translateY(0px);
	opacity: 1;
}

.appear-vertical {
	position: relative;
	transform: translateY(50px);
	opacity: 0;
	transition: all 2s ease;
}

.appear-vertical.appear-vertical-active {
	transform: translateY(0px);
	opacity: 1;
}

.appear-horizontal {
	position: relative;
	transform: translateX(50px);
	opacity: 0;
	transition: all 1.5s ease;
}

.appear-horizontal.appear-horizontal-active {
	transform: translateX(0px);
	opacity: 1;
}

.round-corner {
	border-radius: 10px;
}

/* Footer */
.social-logo {
	margin-right: 20px;
}

.footer {
	border-top: 40px solid #fca000;
	margin-top: 20px;
	/* margin-left: 40px; */
	/* margin-right: 40px; */
	/* background-color: #f0f0f0; */
	padding: 20px;
}

@media only screen and (max-width: 600px) {
	.logo-img {
		width: 50px;
	}

	.brand-logo {
		padding: 0;
		margin: 0;
	}

	.logo-text {
		font-size: 1.6rem;
	}
}
@font-face {
  font-family: "league_spartan";
  src: url('fonts/league_spartan//LeagueSpartan-Bold.otf');
}
@font-face {
  font-family: "libre_baskerville";
  src: url('fonts/libre_baskerville/LibreBaskerville-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: "libre_baskerville-bold";
  src: url('fonts/libre_baskerville/LibreBaskerville-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: "libre_baskerville-italic";
  src: url('fonts/libre_baskerville/LibreBaskerville-Italic.ttf');
  font-weight: italic;
}

body, html {
	height: 100%;
	margin: 0;
	background-color: #f3f3f3;
}

.full-height {
	height: 100%;
	display: block;
	position: relative;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
	max-width: 100%;
}

/** Nav Bar **/
.in-front {
	z-index: 100;
}

.my-nav-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	box-shadow: None;
	z-index: 100;
}

.my-brand-logo {
	/* margin-left: 25px; */
	margin-top: 20px;
	float: left;
}

.my-nav-item {
	text-decoration: none;
	font-family: "league_spartan";
	font-weight: bold;
	font-size: 1.1rem;
	margin-top: 40px;
	color: #b0b3c2;
	line-height: 1.5rem;
	padding: 0px;
	margin-right: 30px;
	margin-left: 30px;
}

.my-nav-item:hover {
	background-color: transparent;
	color: #ffffff;
	border-bottom: 3px solid #f07900;
}

.menu {
  background-color: transparent;
  float: right;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  margin: 0;
}

.show {
	display: block;
	width: 100%;
}

.not-show {
	display: none;
	width: 0px;
}

.my-sidenav {
	position: fixed;
	top: -10;
	z-index: 10;
	height: 100%;
	padding-top: 30vh;
	background-color: #292929;
	margin-top: 0;
	opacity: 0.95;
}

.sidenav-item {
	display: block;
	padding-top: 4vh;
	padding-bottom: 4vh;
	color: #f5f5f5;
	text-decoration: none;
}

.sidenav-item:hover {
	background-color: #f07900;
	color: #fff;
	cursor: pointer;
	font-style: italic;
}

.sidenav-item {
	font-family: "league_spartan";
	color: white;
	font-size: 2rem;
}

.line {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 2;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 2;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 2;
}

.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 2;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 2;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 2;
}

/* console text effect */
.console-underscore {
	display:inline-block;
	position:relative;
	top:-0.14em;
	left:10px;
}

.hidden {
	display: None;
}

/*section */
.section-sub-title {
	display: block;
	font-family: "libre_baskerville-bold";
	color: #ac9488;
	font-size: 0.8rem;
	letter-spacing: 0.15rem;
}

.section-title {
	font-family: "league_spartan";
	font-size: 2.2rem;
	font-weight: bold;
	margin-top: 15px;
	color: #3b3b3b;
}

.section-slogan {
	font-family: "league_spartan";
	font-size: 2rem;
	font-weight: bold;
	margin-top: 15px;
	margin-bottom: 15px;
	color: #000;
}

.section-slogan-divider-left {
	width: 25%;
	border-top: 4px solid black;
	margin-bottom: 30px;
}

.section-slogan-divider {
	width: 100%;
	padding-bottom: 30px;
}

.section-slogan-divider-right {
	width: 25%;
	border-top: 4px solid black;
	float: right;
}

.section-content {
	font-family: "libre_baskerville";
	font-size: 1.1rem;
	line-height: 2rem;
	text-align: justify;
}

.section-img {
	width: 100%;
	float: right;
	margin-top: 20px;
}

/* footer */
footer {
	display: block;
	position: relative;
	padding-top: 100px;
	padding-bottom: 100px;
	background-color: #151515;
}

.footer-container {
	width: 70%;
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}

.footer-nav li {
	font-family: "league_spartan";
	display: inline-block;
	font-weight: bold;
	font-size: 1.1rem;
	margin-top: 20px;
}

.footer-nav li a {
	text-decoration: none;
	color: #f5f5f5;
}

.footer-nav li a:hover, .footer-nav li a:active {
	border-bottom: 3px solid #f07900;
}

.footer-info {
	font-family: "libre_baskerville";
	font-size: 0.9rem;
	margin-top: 30px;
}

.go-top-icon {
	display: none;
	position: absolute;
	top: 10;
	right: -7;
	font-size: 3rem;
	vertical-align: super;
	padding-left: 10px;
	margin: 0;
}

.go-top:hover ~ .go-top-icon, .go-top-icon:hover {
	display: inline-block;
	cursor: pointer;
}

.go-top:hover {
	cursor: pointer;
}

.cursor-pointer {
	cursor: pointer;
}

.margin-left-10 {
	margin-left: 10px;
}

@media only screen and (max-width: 800px) {
	.menu {
		width: 70px;
	}
}

@media only screen and (max-width: 600px) {
	footer {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}
