.contact-bg {
	position: fixed;
	width: 100%;
	height: 110%;
	background-image: url(https://d22sdm4oybjqf2.cloudfront.net/contact_bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	z-index: 0;
}

.form-container {
	z-index: 1;
	padding-top: 100px;
	padding-bottom: 50px;
}

.contact-container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.contact-info-container {
	margin-top: 130px;
	margin-bottom: 40px;
}

.contact-title {
	color: #33daff;
	font-family: "league_spartan", 'Lucida Sans' ;
	line-height: 6rem;
	font-size: 4rem;
}

.contact-subtitle {
	color: #e5e5e5;
	font-family: "libre_baskerville-italic";
	font-size: 1.2rem;
	letter-spacing: 0.1rem;
}

.contact-subinfo {
	color: #e5e5e5;
	font-family: "libre_baskerville";
	font-size: 1rem;
	letter-spacing: 0.1rem;
	line-height: 2rem;
}

.contact-form-container {
	display: block;
	background-color: rgba(0, 0, 0, 0.5);
}

.contact-form-container > form {
	display: block;
	margin: 20px;
	margin-top: 40px;
	margin-bottom: 30px;
}

.contact-form-title {
	color: #ff9800;
	font-family: "league_spartan", 'Lucida Sans' ;
	font-weight: bold;
}

.contact-form-subtitle {
	color: #e5e5e5;
	font-family: "libre_baskerville-italic";
	margin-bottom: 40px;
}

.my-input-field > input{
	color: #e5e5e5;
}

.my-input-field > textarea{
	color: #e5e5e5;
}

.my-input-field > label {
	color: #e5e5e5;
}

 .input-field input:focus {
	border-bottom: 1px solid #f07900 !important;
	box-shadow: 0 1px 0 0 #f07900 !important;
 }

 .input-field textarea:focus {
	border-bottom: 1px solid #f07900 !important;
	box-shadow: 0 1px 0 0 #f07900 !important;
 }

@media only screen and (max-width: 993px) {
	.contact-info-container {
		margin-top: 200px;
	}
}

@media only screen and (max-width: 500px) {
	.contact-info-container {
		margin-top: 250px;
	}

	.contact-title {
		font-size: 2.5rem;
	}

	.contact-subtitle {
		font-size: 0.8rem;
	}

	.contact-subinfo {
		font-size: 0.8rem;
	}
}

@media only screen and (max-width: 400px) {
	.contact-info-container {
		margin-top: 320px;
	}
}
