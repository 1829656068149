.timely-wallpaper {
	width: 100%;
	height: 100vh;
	max-height: 1280px;
	background-image: url(https://d22sdm4oybjqf2.cloudfront.net/timely_poster_dark_2.png);
	background-size: cover;
	background-repeat: no-repeat;
	padding-top: 37vh;
}

.timely-title {
	color: #fff;
	font-family: "league_spartan";
	font-size: 4rem;
	font-weight: bold;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.timely-subtitle {
	font-family: "libre_baskerville-italic";
	font-size: 1.2rem;
	color: #fff;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	text-align: center;
}

.full-width {
	width: 100%;
}

.timely-descript {
	color: white;
	text-transform: uppercase;
	font-weight: bold;
	position: absolute;
	bottom: 5vh;
}

.timely-em {
	color: #55a5e5;
}

.timely-em-2 {
	color: #228be0;
	font-weight: bold;
}

.timely-intro {
	background-color: #000;
	padding-top: 70px;
	padding-bottom: 70px;
	padding-left: 10px;
	padding-right: 10px;
}

.timely-intro-container {
	max-width: 550px;
	padding-left: 10px;
	padding-right: 10px;
	margin-left: auto;
	margin-right: auto;
}

.timely-intro-title {
	color: #fff;
	font-family: "league_spartan";
	font-size: 3.5rem;
	font-weight: bold;
	text-align: center;
}

.timely-intro-content {
	font-family: "libre_baskerville";
	font-size: 1rem;
	color: #fff;
	margin-top: 20px;
	text-align: justify;
}

.timely-intro-content-italic {
	font-family: "libre_baskerville-italic";
	font-size: 1rem;
	color: #fff;
	text-align: center;
}

.timely-intro-web {
	--color: #FFAD00;
	--hover: #FFAD00;
	margin-top: 30px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.timely-btn-container {
	width: 70%;
	max-width: 450px;
}

.timely-intro-streamlit > img {
	width: 25px;
	display: inline-block;
}
.pulse-btn {
	/* --color: #15305c; */
	/* --color: #D35100; */
	font-family: "league_spartan";
	display: inline-block;
	color: var(--color);
	transition: 0.25s;
	background: none;
	background: rgba(0, 0, 0, 0.4);
	border: 2px solid;
	font: inherit;
	font-weight: bold;
	line-height: 1;
	padding: 1em 2em;
	/* box-shadow: 0px 0px 20px #e6c6ba; */
	cursor: pointer;
}

.pulse-btn:hover,
.pulse-btn:focus {
	cursor: pointer;
	color: var(--hover);
	border-color: var(--hover);
	-webkit-animation: pulse 1s;
		  animation: pulse 1s;
	box-shadow: 0 0 0 2em rgba(255, 255, 255, 0);
	transform: scale(0.95);
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}

.timely-download {
	background-color: #001436;
	padding-top: 40px;
	padding-bottom: 30px;
	padding-left: 10px;
	padding-right: 10px;
}

.timely-download-btn-container {
	display: flex;
	max-width: 420px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	flex-wrap: wrap;
}

.timely-download-btn {
	width: 200px;
	margin-left: auto;
	margin-right: auto;
	cursor: pointer;
	transition: all 0.2s ease;
}

.timely-download-btn:hover {
	cursor: pointer;
	transform: scale(1.05);
}

.timely-title-container {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 70px;
	margin-bottom: 50px;
	text-align: center;
}

.timely-section-container {
	width: 75%;
	margin-bottom: 50px;
}

.timely-section-img {
	width: 100%;
	max-width: 400px;
}

.padding-right-20 {
	width: 450px;
	max-width: 450px;
	float: left;
}

.margin-top-40 {
	margin-top: 40px;
}

.timely-logo-img {
	width: auto;
	height: auto;
	max-height: 60px;
	margin-bottom: 40px;
	transition: all 0.2s ease;
}

.timely-logo-img:hover {
	transform: scale(1.1);
	cursor: pointer;
}

.height-70 {
	max-height: 70px;
}

.no-margin-top {
	margin-top: 0;
}

.timely-gestures {
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}

.google-badge {
	margin-top: 2vh;
	max-width: 200px;
}

@media only screen and (max-width: 480px) {
	.timely-wallpaper {
		padding-top: 27vh;
	}
}
