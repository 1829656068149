.textswaphome-wallpaper {
	width: 100%;
	height: 100vh;
	max-height: 1280px;
	background-image: url(https://d22sdm4oybjqf2.cloudfront.net/textswap_wall_dark.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	padding-top: 37vh;
}

.textswaphome-intro-web {
	--color: #faa356;
	--hover: #FFAD00;
	margin-top: 30px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.textswaphome-home-title {
	color: #fff;
	font-family: "league_spartan";
	font-size: 4rem;
	font-weight: bold;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.textswaphome-subtitle {
	font-family: "libre_baskerville-italic";
	font-size: 1.2rem;
	color: #fff;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	text-align: center;
}

.full-width {
	width: 100%;
}

.textswaphome-descript {
	color: white;
	text-transform: uppercase;
	font-weight: bold;
	position: absolute;
	bottom: 5vh;
}

.textswaphome-em {
	color: #fb8c00;
}

.textswaphome-em-2 {
	color: #ef6c00;
	font-weight: bold;
}

.textswaphome-intro {
	background-color: #212121;
	padding-top: 70px;
	padding-bottom: 70px;
	padding-left: 10px;
	padding-right: 10px;
}

.textswaphome-intro-container {
	max-width: 550px;
	padding-left: 10px;
	padding-right: 10px;
	margin-left: auto;
	margin-right: auto;
}

.textswaphome-intro-title {
	color: #e0e0e0;
	font-family: "league_spartan";
	font-size: 3.5rem;
	font-weight: bold;
	text-align: center;
}

.textswaphome-intro-content {
	font-family: "libre_baskerville";
	font-size: 1rem;
	color: #e0e0e0;
	margin-top: 20px;
	text-align: justify;
}

.textswaphome-intro-content-italic {
	font-family: "libre_baskerville-italic";
	font-size: 1rem;
	color: #e0e0e0;
	text-align: center;
}

.text-pulse-btn {
	/* --color: #15305c; */
	/* --color: #D35100; */
	font-family: "league_spartan";
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--color);
	transition: 0.25s;
	background: transparent;
	/* background: rgba(0, 0, 0, 0.4); */
	border: 2px solid;
	font: inherit;
	font-weight: bold;
	line-height: 1;
	padding: 1em 2em;
	/* box-shadow: 0px 0px 20px #e6c6ba; */
	cursor: pointer;
}

.text-pulse-btn:hover,
.text-pulse-btn:focus {
	cursor: pointer;
	color: var(--hover);
	border-color: var(--hover);
	-webkit-animation: pulse 1s;
		  animation: pulse 1s;
	box-shadow: 0 0 0 2em rgba(255, 255, 255, 0);
	transform: scale(0.95);
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}

.textswaphome-download {
	background-color: #001436;
	padding-top: 40px;
	padding-bottom: 30px;
	padding-left: 10px;
	padding-right: 10px;
}

.textswaphome-download-btn-container {
	/* display: flex; */
	max-width: 420px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	/* flex-wrap: wrap; */
}

.textswaphome-download-btn {
	width: 200px;
	margin-left: auto;
	margin-right: auto;
	cursor: pointer;
	transition: all 0.2s ease;
}

.textswaphome-download-btn:hover {
	cursor: pointer;
	transform: scale(1.05);
}

.textswaphome-title-container {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 70px;
	margin-bottom: 50px;
	text-align: center;
}

.textswaphome-section-container {
	width: 75%;
	margin-bottom: 50px;
}

.textswaphome-section-img {
	width: 100%;
	max-width: 400px;
}

.padding-right-20 {
	width: 450px;
	max-width: 450px;
	float: left;
}

.margin-top-40 {
	margin-top: 40px;
}

.height-70 {
	max-height: 70px;
}

.height-50 {
	max-height: 50px;
}

.no-margin-top {
	margin-top: 0;
}

.textswaphome-gestures {
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}

.textswaphome-install-container {
	max-width: 700px;
}

.textswaphome-list-bullet {
	display: inline-block;
	font-family: "league_spartan";
	font-size: 2rem;
	font-weight: bold;
	border: 3px solid black;
	border-radius: 50%;
	height: 60px;
    width: 60px;
    line-height: 60px;
	text-align: center;
	margin-left: 15px;
}

.textswaphome-list-item {
	display: block;
	font-family: "libre_baskerville";
    line-height: 3rem;
	font-size: 1.1rem;
	background-color: #fff;
	border-radius: 20px;
	padding: 20px;
	margin-bottom: 20px;
	box-shadow: 0px 0px 20px 0px #cccccc;
}

.textswaphome-icon {
	font-size: 2.5rem;
	margin-top: 10px;
	margin-bottom: 10px;
	float: right;
}

.textswaphome-logo-img {
	width: auto;
	height: auto;
	max-height: 60px;
	margin-bottom: 40px;
	transition: all 0.2s ease;
}

.textswaphome-logo-img:hover {
	transform: scale(1.1);
	cursor: pointer;
}

.height-70 {
	max-height: 70px;
}

/* .textswaphome-none { */
/* 	display: none; */
/* 	transition: all 0.2s ease; */
/* } */

/* .textswaphome-show { */
/* 	display: block; */
/* 	transition: all 0.2s ease; */
/* } */

/* .textswaphome-sub-list { */
/* 	transition: all 0.2s ease; */
/* } */

.textswaphome-sub-list > strong {
	font-weight: bold;
}

.textswaphome-demo-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	direction: row;
	gap: 20px;
	flex-wrap: wrap;
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 1em;
	padding-right: 1em;
}

.textswaphome-demo-btn {
	--color: #ef6c00;
	--hover: #ef6c00;
	text-align: center;
}

.textswaphome-demo-code-btn {
	--color: #000;
	--hover: #212121;
	text-align: center;
}

.textswaphome-video-1 {
	width: 100%;
	height: auto;
}

.textswaphome-video-2 {
	width: 100%;
	height: auto;
	position: relative;
}

.left-30 {
	left: -30px;
}

@media only screen and (max-width: 680px) {
.textswaphome-icon {
	margin-top: 0px;
	margin-bottom: 0px;
	float: none;
}
}

@media only screen and (max-width: 480px) {
	.textswaphome-wallpaper {
		padding-top: 27vh;
	}

	.textswaphome-list-item {
		font-size: 1rem;
		padding: 15px;
	}

	.textswaphome-list-bullet {
		font-size: 1.5rem;
		height: 40px;
		width: 40px;
		line-height: 40px;
		margin-left: 5px;
	}
}
