.youhand-wallpaper {
	width: 100%;
	height: 100vh;
	max-height: 1280px;
	background-image: url(https://d22sdm4oybjqf2.cloudfront.net/hand_wall.png);
	background-size: cover;
	background-repeat: no-repeat;
	padding-top: 37vh;
}

.youhand-title {
	color: #fff;
	font-family: "league_spartan";
	font-size: 4rem;
	font-weight: bold;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.youhand-subtitle {
	font-family: "libre_baskerville-italic";
	font-size: 1.2rem;
	color: #fff;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	text-align: center;
}

.full-width {
	width: 100%;
}

.youhand-descript {
	color: white;
	text-transform: uppercase;
	font-weight: bold;
	position: absolute;
	bottom: 5vh;
}

.youhand-em {
	color: #55a5e5;
}

.youhand-em-2 {
	color: #228be0;
	font-weight: bold;
}

.youhand-intro {
	background-color: #000;
	padding-top: 70px;
	padding-bottom: 70px;
	padding-left: 10px;
	padding-right: 10px;
}

.youhand-intro-container {
	max-width: 550px;
	padding-left: 10px;
	padding-right: 10px;
	margin-left: auto;
	margin-right: auto;
}

.youhand-intro-title {
	color: #fff;
	font-family: "league_spartan";
	font-size: 3.5rem;
	font-weight: bold;
	text-align: center;
}

.youhand-intro-content {
	font-family: "libre_baskerville";
	font-size: 1rem;
	color: #fff;
	margin-top: 20px;
	text-align: justify;
}

.youhand-intro-content-italic {
	font-family: "libre_baskerville-italic";
	font-size: 1rem;
	color: #fff;
	text-align: center;
}

.youhand-intro-streamlit {
	--color: #fff;
	--hover: #FFAD00;
	margin-top: 30px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.youhand-intro-streamlit > img {
	width: 25px;
	display: inline-block;
}
.pulse-btn {
	/* --color: #15305c; */
	/* --color: #D35100; */
	font-family: "league_spartan";
	display: inline-block;
	color: var(--color);
	transition: 0.25s;
	background: none;
	background: rgba(0, 0, 0, 0.4);
	border: 2px solid;
	font: inherit;
	font-weight: bold;
	line-height: 1;
	padding: 1em 2em;
	/* box-shadow: 0px 0px 20px #e6c6ba; */
	cursor: pointer;
}

.pulse-btn:hover,
.pulse-btn:focus {
	cursor: pointer;
	color: var(--hover);
	border-color: var(--hover);
	-webkit-animation: pulse 1s;
		  animation: pulse 1s;
	box-shadow: 0 0 0 2em rgba(255, 255, 255, 0);
	transform: scale(0.95);
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}

.youhand-download {
	background-color: #001436;
	padding-top: 40px;
	padding-bottom: 30px;
	padding-left: 10px;
	padding-right: 10px;
}

.youhand-download-btn-container {
	/* display: flex; */
	max-width: 420px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	/* flex-wrap: wrap; */
}

.youhand-download-btn {
	width: 200px;
	margin-left: auto;
	margin-right: auto;
	cursor: pointer;
	transition: all 0.2s ease;
}

.youhand-download-btn:hover {
	cursor: pointer;
	transform: scale(1.05);
}

.youhand-title-container {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 70px;
	margin-bottom: 50px;
	text-align: center;
}

.youhand-section-container {
	width: 75%;
	margin-bottom: 50px;
}

.youhand-section-img {
	width: 100%;
	max-width: 400px;
}

.padding-right-20 {
	width: 450px;
	max-width: 450px;
	float: left;
}

.margin-top-40 {
	margin-top: 40px;
}

.youhand-logo-img {
	width: auto;
	height: auto;
	max-height: 60px;
	margin-bottom: 40px;
	transition: all 0.2s ease;
}

.youhand-logo-img:hover {
	transform: scale(1.1);
	cursor: pointer;
}

.height-70 {
	max-height: 70px;
}

.no-margin-top {
	margin-top: 0;
}

.youhand-gestures {
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}

.youhand-install-container {
	max-width: 700px;
}

.youhand-list-bullet {
	display: inline-block;
	font-family: "league_spartan";
	font-size: 2rem;
	font-weight: bold;
	border: 3px solid black;
	border-radius: 50%;
	height: 60px;
    width: 60px;
    line-height: 60px;
	text-align: center;
	margin-left: 15px;
}

.youhand-list-item {
	display: block;
	font-family: "libre_baskerville";
    line-height: 3rem;
	font-size: 1.1rem;
	background-color: #fff;
	border-radius: 20px;
	padding: 20px;
	margin-bottom: 20px;
	box-shadow: 0px 0px 20px 0px #cccccc;
}

.youhand-icon {
	font-size: 2.5rem;
	margin-top: 10px;
	margin-bottom: 10px;
	float: right;
}

/* .youhand-none { */
/* 	display: none; */
/* 	transition: all 0.2s ease; */
/* } */

/* .youhand-show { */
/* 	display: block; */
/* 	transition: all 0.2s ease; */
/* } */

/* .youhand-sub-list { */
/* 	transition: all 0.2s ease; */
/* } */

.youhand-sub-list > strong {
	font-weight: bold;
}

@media only screen and (max-width: 680px) {
.youhand-icon {
	margin-top: 0px;
	margin-bottom: 0px;
	float: none;
}
}

@media only screen and (max-width: 480px) {
	.youhand-wallpaper {
		padding-top: 27vh;
	}

	.youhand-list-item {
		font-size: 1rem;
		padding: 15px;
	}

	.youhand-list-bullet {
		font-size: 1.5rem;
		height: 40px;
		width: 40px;
		line-height: 40px;
		margin-left: 5px;
	}
}
