.sum-about-container {
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	max-width: 1280px;
}

.sum-about-title-container {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
	text-align: center;
}

.sum-about-ava {
	width: 100%;
	float: right;
	margin-top: 20px;
}

.margin-bottom-20 {
	margin-bottom: 20px;
}

.sum-about-slogan {
	font-family: "league_spartan";
	font-size: 2rem;
	font-weight: bold;
	margin-top: 15px;
	margin-bottom: 15px;
	color: #000;
}

.sum-about-divider {
	width: 25%;
	border-top: 4px solid black;
	margin-bottom: 30px;
}

.sum-about-info {
	font-family: "libre_baskerville";
	font-size: 1.1rem;
	line-height: 2rem;
	text-align: justify;
}

.pulse {
	/* --color: #15305c; */
	/* --color: #D35100; */
	--color: #F07900;
	--hover: #F07900;
	font-family: "league_spartan";
	display: inline-block;
	color: var(--color);
	transition: 0.25s;
	background: none;
	border: 2px solid;
	font: inherit;
	font-weight: bold;
	line-height: 1;
	margin-top: 1.5rem;
	margin-right: 2rem;
	padding: 1em 2em;
	box-shadow: 0px 0px 20px #e6c6ba;
}

.pulse:hover,
.pulse:focus {
	cursor: pointer;
	color: var(--hover);
	border-color: var(--hover);
	-webkit-animation: pulse 1s;
		  animation: pulse 1s;
	box-shadow: 0 0 0 2em rgba(255, 255, 255, 0);
	transform: scale(0.95);
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}
