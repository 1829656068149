.rec-container {
	width: 100%;
	display: block;
	padding-top: 45px;
	padding-bottom: 45px;
	margin-top: 120px;
	background-color: #fff;
	position: relative;
}

.rec-container-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 0;
	z-index: -1;
}

.rec-trans-slow {
	transition: height 0.3s linear;
}

.rec-trans-fast {
	transition: height 0.2s linear;
}

.rec-container:hover {
	cursor: pointer;
	background-color: transparent;
}

.rec-container:hover .rec-container-overlay {
	height: 100%;
}

.rec-inner-container {
	width: 70%;
	margin-right: auto;
	margin-left: auto;
	z-index: 2;
}

.rec-container:hover .rec-title, .rec-container:hover .rec-subtitle {
	color: #fff;
}

.rec-margin {
	margin-top: 15px;
	margin-bottom: 0;
}

.rec-subtitle {
	font-weight: normal;
	transition: 0.5s all ease;
}

.rec-title {
	display: inline-block;
	font-size: 3rem;
	letter-spacing: 0.2rem;
	text-decoration: underline;
	color: #000;
	transition: 0.5s all ease;
}

.rec-arrow {
	float: right;
	width: 100px;
	font-stretch: ultra-expanded;
	color: #8c8c8c;
	margin-right: 50px;
	transition: margin-right 0.1s ease 0.3s;
}

.rec-container:hover .rec-arrow {
	filter: invert(100%);
	margin-right: 0px;
}

@media only screen and (max-width: 680px) {
	.rec-arrow {
		display: none;
	}

	.rec-container {
		margin-top: 50px;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.rec-title {
		font-size: 2rem;
	}

}

