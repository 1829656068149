.my-project-container  {
	width: 70%;
	max-width: 1280px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	position: relative;
}

.title-container {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 80px;
	margin-bottom: 50px;
	text-align: center;
}

/* project cards */
/* car background */
.hand-recog {
	background-image: url(../../images/hand_recog.png);
}

.timely {
	background-image: url(../../images/timely_poster_dark.png);
}

.sudoku {
	background-image: url(../../images/sudoku_dark.png);
}

.omp {
	background-image: url(../../images/omp_poster_dark.png);
	background-position: center top;
}

.textswap {
	background-image: url(../../images/ai-poster.gif);
}

.anime-char {
	background-image: url(../../images/serena_wall_dark.png);
}

/* .keypoint { */
/* 	background-image: url(../../images/keypoint_tf_poster.png); */
/* } */

/* .sorting { */
/* 	/1* background-image: url(../../images/sorting_dark.png); *1/ */
/* 	/1* background-image: url(../../images/sorting2.png); *1/ */
/* 	background-image: url(../../images/sorting3.png); */
/* } */

.card-container {
	display: inline-block;
	position: relative;
	max-height: 550px;
	max-width: 950px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 130px;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 7%;
	padding-top: 25%;
	color: #fff;
	box-shadow: 0px 10px 80px 5px rgba(100, 100, 100, 0.6);
}

.card-container:hover {
	cursor: pointer;
}

.card-content {
	position: relative;
	z-index: 2;
	transition: all 0.2s linear 0.25s;
	cursor: pointer;
}

.card-right {
	right: -2%;
}

.card-left {
	left: -2%;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.card-title {
	font-family: "league_spartan";
	font-size: 3rem;
	font-weight: bold;
}

.card-subtitle {
	font-family: "libre_baskerville-italic";
	font-size: 1.2rem;
}

.card-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 100%;
	z-index: 0;
	background-color: #293a68;
	background: linear-gradient(to right,#141a2b, #183864);
	transition: width 0.3s linear 0.1s;
	opacity: 0.95;
	cursor: pointer;
}

.card-overlay {
	cursor: pointer;
}

.number-right {
	right: -1.5rem;
}

.number-left {
	left: -1.5rem;
}

.card-number {
	font-family: "league_spartan";
	position: absolute;
	top: -1rem;
	font-size: 12rem;
	color: #c2c2c2;
	opacity: 0;
	transition: all 0.3s ease 0.2s;
}

.card-container:hover .card-number {
	opacity: 1;
	top: -3rem;
}

.card-number-overlay {
	font-family: "league_spartan";
	position: absolute;
	top: 0;
	left: 0;
	font-size: 12rem;
	height: 0%;
	overflow: hidden;
	color: #fff;
	transition: height 0s linear 0s;
}

.card-container:hover .card-number-overlay {
	transition: height 0.2s linear 0.5s;
	height: 100%;
}

.card-container:hover .card-overlay {
	width: 100%;
}

.card-container:hover .card-content {
	transform: scale(1.05);
}

/* .card-btn1 { */
/*   --offset: 10px; */
/*   --border-size: 1.5px; */
/*   margin-top: 25px; */
/*   display: inline-block; */
/*   position: relative; */
/*   padding: 1em 2em; */
/*   -webkit-appearance: none; */
/*      -moz-appearance: none; */
/*           appearance: none; */
/*   border: 0; */
/*   background: transparent; */
/*   color: #f8a145; */
/*   /1* color: #ffb300; *1/ */
/*   text-transform: uppercase; */
/*   letter-spacing: 0.25em; */
/*   outline: none; */
/*   cursor: pointer; */
/*   font-weight: bold; */
/*   font-size: 0.9rem; */
/*   border-radius: 0; */
/*   text-shadow: 0px 0px 1px white; */
/*   /1* box-shadow: inset 0 0 0 var(--border-size) currentcolor; *1/ */
/*   /1* box-shadow: 0px 0px 2px 1px black; *1/ */
/*   transition: background 0.8s ease; */
/* } */

/* .card-btn:hover { */
/*   background: rgba(255, 255, 255, 0.2); */
/*   transition: background 0.1s ease; */
/* } */

/* .card-btn:active { */
/*   background: rgba(0, 0, 0, 0.01); */
/* } */

/* .card-btn-hori, .card-btn-vert { */
/*   position: absolute; */
/*   top: var(--horizontal-offset, 0); */
/*   right: var(--vertical-offset, 0); */
/*   bottom: var(--horizontal-offset, 0); */
/*   left: var(--vertical-offset, 0); */
/*   transition: transform 0.8s ease; */
/*   box-shadow: 1px 1px 5px 0px black; */
/*   will-change: transform; */
/* } */

/* .card-btn-hori::before, .card-btn-vert::before { */
/*   content: ""; */
/*   position: absolute; */
/*   border: inherit; */
/* } */

/* .card-btn-hori { */
/*   --vertical-offset: calc(var(--offset) * -1); */
/*   border-top: var(--border-size) solid currentcolor; */
/*   border-bottom: var(--border-size) solid currentcolor; */
/* } */

/* .card-btn-hori::before { */
/*   top: calc(var(--vertical-offset) - var(--border-size)); */
/*   bottom: calc(var(--vertical-offset) - var(--border-size)); */
/*   left: calc(var(--vertical-offset) * -1); */
/*   right: calc(var(--vertical-offset) * -1); */
/* } */

/* .card-btn:hover .card-btn-hori { */
/*   transform: scaleX(0); */
/* } */

/* .card-btn-vert { */
/*   --horizontal-offset: calc(var(--offset) * -1); */
/*   border-left: var(--border-size) solid currentcolor; */
/*   border-right: var(--border-size) solid currentcolor; */
/* } */

/* .card-btn-vert::before { */
/*   top: calc(var(--horizontal-offset) * -1); */
/*   bottom: calc(var(--horizontal-offset) * -1); */
/*   left: calc(var(--horizontal-offset) - var(--border-size)); */
/*   right: calc(var(--horizontal-offset) - var(--border-size)); */
/* } */

/* .card-btn:hover .card-btn-vert { */
/*   transform: scaleY(0); */
/* } */

.card-btn {
	/* --color: #15305c; */
	/* --color: #D35100; */
	--color: #FFAD00;
	--hover: #FFB619;
	font-family: "league_spartan";
	display: inline-block;
	color: var(--color);
	transition: 0.25s;
	background: none;
	background: rgba(0, 0, 0, 0.4);
	border: 2px solid;
	font: inherit;
	font-weight: bold;
	line-height: 1;
	margin-top: 1.5rem;
	padding: 1em 2em;
	/* box-shadow: 0px 0px 20px #e6c6ba; */
	cursor: pointer;
}

.card-btn:hover,
.card-btn:focus {
	cursor: pointer;
	color: var(--hover);
	border-color: var(--hover);
	-webkit-animation: pulse 1s;
		  animation: pulse 1s;
	box-shadow: 0 0 0 2em rgba(255, 255, 255, 0);
	transform: scale(0.95);
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}
@media only screen and (max-width: 700px) {
	.my-container  {
		width: 80%;
	}

	.card-container {
		margin-bottom: 90px;
		box-shadow: 0px 5px 40px 5px rgba(100, 100, 100, 0.6);
		padding: 10%;
		padding-top: 25%;
	}

	.card-title {
		font-size: 2rem;
	}

	.card-subtitle {
		font-size: 0.8rem;
	}

	.card-btn {
		--border-size: 1px;
		font-size: 0.8rem;
	}

	.card-number {
		font-size: 5rem;
		top: 0;
	}

	.card-number-overlay {
		font-size: 5rem;
	}

	.card-container:hover .card-number {
		opacity: 1;
		top: -1.5rem;
	}

	.number-right {
		right: -1rem;
	}

	.number-left {
		left: -1rem;
	}
	.title-container {
		margin-top: 60px;
	}

	.card-right {
		right: 0;
	}

	.card-left {
		left: 0;
	}
}

@media only screen and (max-width: 500px) {
	.card-title {
		font-size: 1.7rem;
	}

	.card-subtitle {
		font-size: 0.7rem;
	}
}

