.chatbot-container {
	position: fixed;
	bottom: 30px;
	right: 30px;
	z-index: 100;
	max-width: 80%;
	width: auto;
	height: auto;
}

.chatbot-icon {
	position: fixed;
	bottom: 30px;
	right: 30px;
	width: 70px;
	z-index: 100;
	height: 70px;
	border-radius: 50%;
	background-color: #ebf3ff;
	overflow: hidden;
	transition: 0.2 ease all;
	/* padding-top: 5px; */
	box-shadow: 1px 1px 10px gray;
}

.chatbot-icon img {
	width: 70px;
	height: 70px;
	position: relative;
	top: 5px;
}

.chatbot-icon:hover {
	cursor: pointer;
	transform: scale(1.05);
}

.chat-container {
	width: 350px;
	height: 500px;
	max-width: 100%;
	background-color: white;
	border-radius: 15px;
	border-bottom-right-radius: 0px;
	box-shadow: 1px 1px 10px #b0b0b0;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.chatbot-header {
	width: 100%;
	height: 70px;
	background-color: #183864;
	border-bottom-right-radius: 15px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
}

.chatbot-header-info {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.chatbot-header-ava {
	width: 45px;
	height: 45px;
	background-color: #ebf3ff;
	border-radius: 50%;
	overflow: hidden;
	text-align: center;
	padding-top: 5px;
}

.chatbot-header-ava img {
	width: 45px;
	height: 45px;
}

.chatbot-header-name {
	color: white;
	font-family: "league_spartan";
	/* font-weight: bold; */
	margin-left: 10px;
}

.chatbot-header-close {
	transition: 0.2s ease all;
	cursor: pointer;
}

.chatbot-header-close:active {
	transform: scale(0.9);
	color: #85969e;
}

.chatbot-input textarea:focus {
	 color: #000 ;
}
.chatbot-input textarea:focus {
	 border-bottom: 0px solid #000 !important;
	 box-shadow: 0 0px 0 0 #000 !important;
}

.chatbot-input textarea {
	 border-bottom: 0px solid #000 !important;
	 box-shadow: 0 0px 0 0 #000 !important;
	 height: 50px;
	 margin-right: 10px;
}

.chatbot-input {
	border-top: 2px solid #e0e0e0;
	padding-left: 20px;
	padding-right: 10px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.chatbot-dialog {
	flex-grow: 1;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}

.bot-response-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-end;
	width: 90%;
	margin-top: 20px;
}

.bot-response-ava {
	width: 40px;
	height: 40px;
	background-color: #b3e5fc;
	background-color: #ebf3ff;
	border-radius: 20px;
	overflow: hidden;
	padding-top: 5px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	max-width: 20%;
}

.bot-response-ava img {
	width: 40px;
	height: 40px;
	max-width: 100%;
}

.bot-response-message {
	max-width: 80%;
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: 10px;
	background-color: #eceff1;
	color: black;
	border-radius: 15px;
	border-bottom-left-radius: 0px;
	white-space: pre-wrap;
}

.user-response-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-end;
	width: 90%;
	margin-top: 20px;
	align-self: flex-end;
}

.user-response-message {
	max-width: 80%;
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: 10px;
	background-color: #183864;
	color: white;
	border-radius: 15px;
	border-bottom-right-radius: 0px;
	white-space: pre-wrap;
}

.typing {
	align-items: center;
	display: flex;
	height: 17px;
}
.typing .dot {
	animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
	background-color: #ffa000;
	border-radius: 50%;
	height: 7px;
	margin-right: 4px;
	vertical-align: middle;
	width: 7px;
	display: inline-block;
}
.typing .dot:nth-child(1) {
	animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
	animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
	animation-delay: 400ms;
}
.typing .dot:last-child {
	margin-right: 0;
}

@keyframes mercuryTypingAnimation {
	0% {
		transform: translateY(0px);
		background-color: #ffc107;
	}
	28% {
		transform: translateY(-7px);
		background-color: #ffa000;
	}
	44% {
		transform: translateY(0px);
		background-color: #ff8f00;
	}
}

.chatbot-send-btn {
	transition: 0.2 ease all;
	color: #1a237e;
}

.chatbot-send-btn:hover {
	transform: scale(1.1);
	color: #3949ab;
}

.chatbot-send-btn:active {
	transform: scale(1);
	color: #303f9f;
}

@media only screen and (max-width: 500px) {
.open-chat {
	width: 100%;
	height: 100%;
	bottom: 0px;
	left: 0px;
	max-width: 100%;
}

.chat-container {
	width: 100%;
	height: 100%;
	max-width: 100%;
}
}

.chatbot-popup-message-container {
	position: fixed;
	bottom: 110px;
	right: 50px;
	width: 80%;
	max-width: 250px;
	z-index: 300;
}

.chatbot-popup-message {
	position: relative;
	width: 100%;
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: 10px;
	background-color: #eceff1;
	color: black;
	border-radius: 15px;
	border-bottom-right-radius: 0px;
	white-space: pre-wrap;
	box-shadow: 1px 1px 7px gray;
}

.chatbot-popup-close {
	position: absolute;
	top: -8px;
	right: -5px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	width: 20px;
	height: 20px;
	border-radius: 10px;
	border: 1px solid #01579b;
}

.chatbot-popup-close i {
	font-size: 0.9rem;
	color: #01579b;
}
