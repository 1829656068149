.full-container {
	width: 100%;
	display: block;
	position: relative;
}

/**** WALL PAPER ****/
.vertical-line {
	border-left: 1px solid #e5e5e5;
	height: 100%;
	width: 1px;
	position: fixed;
	top: 0;
	left: 50%;
	z-index: -1;
}

/* transition effect */ .overflow-x-hidden {
	overflow-x: hidden;
}

.overflow-y-hidden {
	overflow-y: hidden;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 0;
  background-color: #15305c;
  padding: 0;
  margin: 0;
}

.slide-in {
	animation: expand .8s ease forwards;
	transition: 0.8 ease all;
}

@keyframes expand {
  0% {
    transform: translateX(1000px);
  }
  100% {
    transform: translateX(0px);
  }
}

.bg-dark {
	background-image: url(../../images/wallpaper_bg.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 100%;
	z-index: -1;
	position: relative;
}

.bg-logo {
	position: absolute;
	top: 50%;
	left: 60%;
	width: 600px;
	height: 600px;
	margin-top: -300px; /* Half the height */
	margin-left: -300px;
}

.particle-canvas{
	background-color: #1c1e24;
	width: 100%;
	height: 100%;
	margin: 0;
	z-index: -1;
}

.bg-logo-overlay {
	position: absolute;
	top: 50%;
	left: 60%;
	width: 0px;
	height: 600px;
	margin-top: -300px; /* Half the height */
	margin-left: 0px;
	animation: swipe_reveal 0.8s linear 1.2s;
	z-index: 0;
	background-color: #1c1e24;
}

@keyframes swipe_reveal {
	from {
		width: 600px;
		margin-left: -300px;
	}

	to {
		width: 0;
		margin-left: 300px;
	}
}

.my-info {
	position: absolute;
	top: 35%;
	left: 20%;
}

.my-name {
	font-family: "league_spartan", 'Lucida Sans' ;
	line-height: 6rem;
	font-size: 4.5rem;
}

.subtitle {
	font-family: "libre_baskerville-italic";
	font-size: 1.5rem;
	letter-spacing: 0.1rem;
}

/* Get Started button */
.blank-space {
	margin: 60px;
}
.cta {
	display: inline-block;
	position: relative;
	padding: 16px 22px;
	transition: all 0.2s ease;
}

.cta:hover {
	cursor: pointer;
}

.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 28px;
  background: #f07900;
  width: 56px;
  height: 56px;
  transition: all 0.3s ease;
}

.cta span {
  position: relative;
  font-size: 1.5rem;
  color: #fff;
  line-height: 18px;
  font-weight: 900;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  vertical-align: middle;
}

.cta svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #fff;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.cta:hover span {
	color: #000;
}

.cta:hover:before {
  width: 100%;
  background: #ffa000;
}

.cta:hover svg {
  transform: translateX(0);
  stroke: #000;
}

.cta:active {
  transform: scale(0.96);
}


/* social bar */
.social-bar {
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -200px;
}

.social-logo {
	display: block;
	margin-bottom: 30px;
	color: #b0b3c2;
	transition: 0.3s ease all;
}

.social-logo:hover, .social-logo:active {
	color: #fff;
	cursor: pointer;
}


@media only screen and (max-width: 900px) {
	.bg-logo {
		position: absolute;
		top: 50%;
		width: 350px;
		height: 350px;
		margin-top: -175px; /* Half the height */
		margin-left: -175px;
	}

	.my-info {
		top: 40%;
		left: 10%;
		margin-left: 20px;
	}

	.my-name {
		font-size: 3.6rem;
	}

	.subtitle {
		font-size: 1.3rem;
	}

	.cta span {
		font-size: 1.2rem;
	}
}

@media only screen and (max-width: 600px) {
	.bg-logo {
		position: absolute;
		top: 50%;
		width: 250px;
		height: 250px;
		margin-top: -125px; /* Half the height */
		margin-left: -125px;
	}

	.subtitle {
		font-family: "libre_baskerville-italic";
		font-size: 1.1rem;
		letter-spacing: 0.1rem;
	}

	.my-info {
		top: 35%;
		left: 4%;
		right: 5%;
	}

	.my-name {
		font-size: 2.7rem;
	}

}

@media only screen and (max-width: 500px) {
	.my-name {
		font-size: 2.5rem;
	}

	.my-info {
		top: 30%;
	}
}
