.page-container {
	font-family: "league_spartan";
	width: 100%;
	background-color: #616161;
	height: 100%;
	overflow-y: hidden;
}

.header-bar {
	width: 100%;
	height: 100px;
	background-color: #212121;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
}

.textswap-header-container {
	width: 90%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.textswap-header-tool-container, .textswap-logo-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.textswap-title {
	color: white;
	font-size: 2rem;
	margin-left: 30px;
}

.textswap-logo-img {
	width: 60px;
	height: 60px;
	display: inline-block;
}

.img-dropzone-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 2px solid white;
	border-radius: 10px;
	border-style: dashed;
	padding: 20px;
	max-width: 500px;
	min-width: 200px;
	width: 100%;
}

.img-dropzone-container:hover {
	cursor: pointer;
}

.img-upload-icon {
	font-size: 5rem;
}

.canvas-container {
	width: 100%;
	height: 100%;
	min-height: 500px;
	background-color: #424242;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.editor-container {
	display: flex;
	flex-direction: row;
	border-spacing: 0;
	justify-content: center;
	height: 100%;
}

.tool-container {
	height: 100%;
	display: block;
	background-color: #616161;
	border-right: 1px solid #212121;
	z-index: 10;
	padding: 5px;
	padding-bottom: 10px;
}

.tool-icon {
	font-size: 1.5rem;
}

.tool-btn {
	margin-top: 5px;
	height: 40px;
	width: 50px;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
}

.tool-btn-border {
	border: 1px solid #212121;
}

.tool-btn:hover {
	cursor: pointer;
}

.textswap-btn {
	padding: 10px;
	padding-left: 15px;
	padding-right: 15px;
	color: #faa356;
	border: 1px solid #faa356;
	margin-left: 15px;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.textswap-btn:hover {
	background-color: rgba(250, 163, 86, 0.1);
	cursor: pointer;
	color: #f7aa65;
	border: 1px solid #f7aa65;
}

.textswap-btn:active {
	background-color: rgba(230, 139, 60, 0.1);
	cursor: pointer;
	color: #e68b3c;
	border: 1px solid #e68b3c;
}

.textswap-btn-solid {
	padding: 10px;
	padding-left: 15px;
	padding-right: 15px;
	color: white;
	background: linear-gradient(-45deg, #1153fc, #5581f1);
	margin-left: 20px;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.textswap-btn-solid:hover {
	background: linear-gradient(-45deg, #2761f5, #688eed);
	cursor: pointer;
}

.textswap-btn-solid:active {
	background: linear-gradient(-45deg, #0946e3, #3861c9);
	cursor: pointer;
}

.textswap-btn-flat {
	color: #cfcccc;
	margin-left: 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.textswap-btn-flat:hover {
	color: #e0e0e0;
	cursor: pointer;
}

.textswap-btn-flat:active {
	color: #8a8787;
	cursor: pointer;
}

.inspector-container {
	height: 100%;
	width: 35%;
	min-width: 300px;
	max-width: 400px;
	background-color: #616161;
	border-left: 1px solid #212121;
	padding: 25px;
}

.inspector-text-label {
	background-color: #e0e0e0;
	overflow: auto;
	color: black;
	border: 1px solid #212121;
	border-radius: 5px;
	width: 100%;
	height: 150px;
	margin-bottom: 20px;
	font-family: "Arial";
}

.inspector-textarea {
	background-color: white;
	color: black;
	border: 1px solid #212121;
	border-radius: 5px;
	width: 100%;
	height: 150px;
	margin-bottom: 20px;
	font-family: "Arial";
}

.inspector-title {
	width: 35%;
}

.inspector-section {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 10px;
}

.inspector-section-select {
	width: 40%;
	margin-right: 5%;
}

.inspector-section-input {
	max-width: 50px;
	width: 20%;
	margin: 0 !important;
	padding-left: 5px !important;
}

.inspector-section-input-2 {
	max-width: 70px;
	width: 30%;
	margin: 0 !important;
	padding-left: 5px !important;
}

.inspector-align-btn {
	height: 40px;
	width: 50px;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
}

.inspector-align-btn:hover {
	cursor: pointer;
}

.inspector-description {
	font-family: "libre_baskerville";
	font-size: 0.9rem !important;
	line-height: 1.5rem;
}

.checkbox-orange[type="checkbox"].filled-in:checked+span:not(.lever):after {
  border: 2px solid #faa356;
  background-color: #faa356;
}

.checkbox-orange[type="checkbox"].filled-in:not(:checked)+span:not(.lever):after {
  border: 2px solid #faa356;
}

.inspector-checkbox {
	margin-top: 25px;
}

input[type="range"]::-webkit-slider-thumb {
	background-color: #faa356;
}
input[type="range"]::-moz-range-thumb {
	background-color: #faa356;
}
input[type="range"]::-ms-thumb {
	background-color: #faa356;
}

/***** These are to edit the thumb and the text inside the thumb *****/
input[type="range"] + .thumb {
	background-color: white;
}
input[type="range"] + .thumb.active .value {
	color: #faa356;
}

#toast-container {
  top: auto !important;
  right: auto !important;
  bottom: 7%;
  left: 7%;
}

.loading-dot:before {
    animation: dots 2s linear infinite;
    content: '';
  }

@keyframes dots {
    0%, 20% {
      content: '.';
    }
    40% {
      content: '..';
    }
    60% {
      content: '...';
    }
    90%, 100% {
      content: '';
    }
}

.textswap-error-text-container {
	color: white;
	width: 100%;
	height: 90%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5rem;
	padding-left: 20px;
	padding-right: 20px;
	flex-direction: column;
}
